import { Fragment, useState } from "react";
import "./Wellcome.scss";
import SuccessModal from "../../components/success-modal/SuccessModal";
import EmailForm from "../../components/email/Email";
import { subscribe } from "../../constants";
import IconTwitter from "../../assets/images/ic-twitter.svg";
import IconInstagram from "../../assets/images/ic-instagram.svg";
import IconLinkedin from "../../assets/images/ic-linkedin.svg";
// import IconDiscrod from "../../assets/images/ic-discord.svg";
import IconTelegram from "../../assets/images/ic-telegram.svg";
import IconMedium from "../../assets/images/ic-medium.svg";
import SocialAccount from "../../components/social-account";
import Logo from "../../assets/images/logo.svg";

const Wellcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalHandle = () => setIsOpen(true);
  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  return (
    <>
      <div className="wellcome wellcome-bg">
        <div className="flex-1 flex justify-center container">
          <div className="wellcome__flexcenter">
            <h1>
              <img
                className="-mt-3 md:w-auto w-48"
                src={Logo}
                alt="Blockchaintime"
              />
            </h1>
            <img
              className="mt-9"
              src={require(`../../assets/images/magazine.png`)}
              alt="Blockchaintime"
              srcSet={`${require(`../../assets/images/magazine.png`)} 1x, ${require(`../../assets/images/magazine@2x.png`)} 2x`}
            />
            <h2>
              Transparent news experience with{" "}
              <span className="font-semibold">blockchain</span>.
            </h2>
            <h4>
              <span className="font-semibold">Blockchaintime</span> is a
              bimonthly magazine that will be created in the form of NFTs.
              Blockchaintime will eliminate the{" "}
              <br className="xl:block hidden" />
              information pollution to provide transparent useful content, and
              enjoyable experiences for individuals.
            </h4>
            <h3>Our website is under construction</h3>
            <p className="text-[#2A2528] opacity-50">
              To learn more about CovenLabs projects and get project launch
              notifications, <br className="lg:block hidden" />
              please subscribe to our newsletter.
            </p>
            <EmailForm
              config={subscribe}
              btnText="Subscribe"
              modalHandle={modalHandle}
            />
          </div>
        </div>
        <div className="wellcome__social">
          <SocialAccount
            icon={IconTwitter}
            title={"Twitter"}
            link={"https://twitter.com/covenlabsxyz"}
          />
          <SocialAccount
            icon={IconInstagram}
            title={"Instagram"}
            link={"https://www.instagram.com/covenlabs.xyz"}
          />
          <SocialAccount
            icon={IconLinkedin}
            title={"Linkedin"}
            link={"https://www.linkedin.com/company/covenlabs"}
          />
          {/* <SocialAccount
                icon={IconDiscrod}
                title={"Discord"}
                link={"/DC"}
              /> */}
          <SocialAccount
            icon={IconTelegram}
            title={"Telegram"}
            link={"https://t.me/+GO0C0gSsZsRjM2Vk"}
          />
          <SocialAccount
            icon={IconMedium}
            title={"Medium"}
            link={"https://medium.com/@covenlabs"}
          />
          <span className="w-full text-center text-[#949295] mt-5">
            © 2022 CovenLabs
          </span>
        </div>
      </div>

      {isOpen ? (
        <SuccessModal
          closeModalState={closeModalState}
          title="Subscribed!"
          description={
            <Fragment>
              You will receive the latest updates and we can't{" "}
              <br className="md:block hidden" />
              wait to share our projects with you.
            </Fragment>
          }
        />
      ) : null}
    </>
  );
};

export default Wellcome;
